<template>
  <!-- <div id="map"></div> -->
  <baidu-map
    id="map"
    :center="center"
    :zoom="zoom"
    :scroll-wheel-zoom="true"
    @ready="handler"
  >
    <div v-for="item in pointList" :key="item.id">
      <bm-marker
        :position="item.point"
        :icon="{
          url: require('../../assets/positioner.png'),
          size: { width: 50, height: 50 },
        }"
        @click="infoWindowOpen(item)"
      >
        <bm-info-window
          class="windows"
          :show="item.show"
          title="设备信息"
          @close="infoWindowClose(item)"
        >
          {{ item.content }}
        </bm-info-window>
      </bm-marker>
    </div>
    <bm-marker
      :position="center"
      v-if="isAlarm"
      :icon="{
        url: require('../../assets/positioner.png'),
        size: { width: 50, height: 50 },
      }"
      @click="infoAlarmWindowOpen"
    >
      <bm-info-window
        class="windows"
        :show="show"
        :title="alarmTitle"
        @close="infoAlarmWindowClose"
        @open="infoAlarmWindowOpen"
      >
        {{ content }}
      </bm-info-window>
    </bm-marker>
    <bm-polyline
      :path="polylinePath"
      v-if="isFoldLine"
      stroke-color="red"
      :stroke-opacity="0.5"
      :stroke-weight="2"
      @lineupdate="updatePolylinePath"
    >
    </bm-polyline>
    <!-- 自定义 start-->
    <bm-marker
      :position="{ lng: customMarker.lng, lat: customMarker.lat }"
      :animation="customMarker.animation"
    >
    </bm-marker>
    <bm-point-collection
      :points="customPoint"
      shape="BMAP_POINT_SHAPE_WATERDROP"
      color="red"
      size="BMAP_POINT_SIZE_HUGE"
    ></bm-point-collection>
    <!-- 自定义 end-->
  </baidu-map>
</template>

<script>
import { validatenull } from "../../echart/util";
export default {
  name: "customAMap",
  data() {
    return {
      videoList: [],
      center: { lng: 123.429096, lat: 41.796767 },
      zoom: 13,
      show: false,
      content: "",
      isAlarm: false,
      alarmTitle: "",
      isFoldLine: false,
      polylinePath: [],
      pointList: [],
      customPoint: [], // 地图自定义mark及label
      customMarker: {},
      map: null,
    };
  },
  created(){
    // 使地图默认中心为 吉林长春，如果有设备定位则使用最后定位为中心点
    this.center = JSON.parse(localStorage.getItem("cameraMapCenter")) ||  { lng: 125.35, lat: 43.88 }
  },
  mounted() {
    this.$bus.$on("onTrajectory", (data) => {
      //绑定当前事件（这里以hello为例）
      this.polylinePath = [];
      if (data.length > 1) {
        this.isFoldLine = true;
        this.polylinePath = data;
        this.center = data[0];
      } else {
        this.isFoldLine = false;
      }
    });
    this.$bus.$on("onYesterdayTrajectory", (data) => {
      //绑定当前事件（这里以hello为例）
      this.polylinePath = [];
      if (data.length > 1) {
        this.isFoldLine = true;
        this.polylinePath = data;
        this.center = data[0];
      } else {
        this.isFoldLine = false;
      }
    });
    this.$bus.$on("userInfoList", (data) => {
      if (data) {
        data.forEach((item) => {
          item.point = {
            lng: item.location.mapLongitude,
            lat: item.location.mapLatitude,
          };
          item.content =
            "用户名称：" +
            item.userRosterName +
            "\n设备号：" +
            item.deviceId +
            "\n地址：" +
            item.location +
            "\n时间：" +
            item.alarmTime;
          this.pointList.push(item);
        });
      }
    });
    this.$bus.$on("userInfo", (data) => {
      if (
        data.location.mapLongitude === "" &&
        data.location.mapLatitude === ""
      ) {
        this.$confirm("设备未取到经纬度，请联系上级！");
        return;
      }
      this.isAlarm = true;
      this.zoom = 10;
      this.pointList = [];
      data.point = {
        lng: data.location.mapLongitude,
        lat: data.location.mapLatitude,
      };
      this.center = {
        lng: data.location.mapLongitude,
        lat: data.location.mapLatitude,
      };
      data.content =
        "用户名称：" +
        data.userRosterName +
        "\n设备号：" +
        data.deviceId +
        "\n地址：" +
        data.location +
        "\n时间：" +
        data.alarmTime;
      this.pointList.push(data);
    });
    this.$bus.$on("alarmClick", (data) => {
      this.isAlarm = true;
      this.alarmTitle = data.row.alarmType;
      this.center = {
        lng: data.row.locationList.mapLongitude,
        lat: data.row.locationList.mapLatitude,
      };
      this.content =
        "报警用户：" +
        data.row.userRosterName +
        "\n设备号：" +
        data.row.deviceId +
        "\n报警地址：" +
        data.row.location +
        "\n报警时间：" +
        data.row.alarmTime;
    });
  },
  beforeDestroy() {
    //收尾操作，销毁
    this.$bus.$off("onTrajectory"); //$off解绑当前组件所用到的事件
    this.$bus.$off("onYesterdayTrajectory");
    this.$bus.$off("userInfoList");
    this.$bus.$off("userInfo");
    this.$bus.$off("alarmClick");
  },
  methods: {
    handler({ BMap, map }) {
      this.map = map;
      this.content =
        "用户：李哲\n设备：87687696986798\n地址：辽宁省本溪市溪湖区少连赛街车寨陈家沟楼\n时间：2023-05-03 08：47：00";
    },
    infoWindowClose(e) {
      e.show = false;
    },
    infoWindowOpen(e) {
      // if(this.videoList.length === 0){
      // 	this.videoList.push(e)
      // }else if(this.videoList.length > 2){
      // 	this.$confirm('您查看得设备超过2个，请进入监控列表')
      // }else{
      // 	for(let i=0;i<this.videoList.length;i++){
      // 		// if(e.deviceId !== this.videoList[i].deviceId){
      // 		if(e !== this.videoList[i]){
      // 			this.videoList.push(e)
      // 		}
      // 	}
      // }
      this.$bus.$emit("videoMap", e);
      e.show = true;
    },
    infoAlarmWindowClose() {
      this.show = false;
      // this.isAlarm = false
    },
    infoAlarmWindowOpen() {
      this.show = true;
    },
    updatePolylinePath(e) {
      //轨迹
      this.polylinePath = e.target.getPath();
    },
    // 批量添加自定义点
    pushCustomPoint(arr = []) {
      this.customPoint = arr;
      this.customMarker = {
        ...arr[0],
        animation: "BMAP_ANIMATION_BOUNCE",
      };
    },
    // 根据经纬度移动地图到特定坐标
    panTo(param) {
      if (!validatenull(param)) {
        const point = new BMap.Point(param.lng, param.lat);
        this.map.panTo(point);
      }
    },
  },
};
</script>

<style lang="scss">
#map {
  width: 938px;
  height: 575px;
}
.windows {
  white-space: pre-line;
  width: 300px;
}
//去掉水印
.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}
</style>
