var render = function render(){var _vm=this,_c=_vm._self._c;return _c('baidu-map',{attrs:{"id":"map","center":_vm.center,"zoom":_vm.zoom,"scroll-wheel-zoom":true},on:{"ready":_vm.handler}},[_vm._l((_vm.pointList),function(item){return _c('div',{key:item.id},[_c('bm-marker',{attrs:{"position":item.point,"icon":{
        url: require('../../assets/positioner.png'),
        size: { width: 50, height: 50 },
      }},on:{"click":function($event){return _vm.infoWindowOpen(item)}}},[_c('bm-info-window',{staticClass:"windows",attrs:{"show":item.show,"title":"设备信息"},on:{"close":function($event){return _vm.infoWindowClose(item)}}},[_vm._v(" "+_vm._s(item.content)+" ")])],1)],1)}),(_vm.isAlarm)?_c('bm-marker',{attrs:{"position":_vm.center,"icon":{
      url: require('../../assets/positioner.png'),
      size: { width: 50, height: 50 },
    }},on:{"click":_vm.infoAlarmWindowOpen}},[_c('bm-info-window',{staticClass:"windows",attrs:{"show":_vm.show,"title":_vm.alarmTitle},on:{"close":_vm.infoAlarmWindowClose,"open":_vm.infoAlarmWindowOpen}},[_vm._v(" "+_vm._s(_vm.content)+" ")])],1):_vm._e(),(_vm.isFoldLine)?_c('bm-polyline',{attrs:{"path":_vm.polylinePath,"stroke-color":"red","stroke-opacity":0.5,"stroke-weight":2},on:{"lineupdate":_vm.updatePolylinePath}}):_vm._e(),_c('bm-marker',{attrs:{"position":{ lng: _vm.customMarker.lng, lat: _vm.customMarker.lat },"animation":_vm.customMarker.animation}}),_c('bm-point-collection',{attrs:{"points":_vm.customPoint,"shape":"BMAP_POINT_SHAPE_WATERDROP","color":"red","size":"BMAP_POINT_SIZE_HUGE"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }