// 底层组件库
import barOption from "./components/bar";
import datetimeOption from "./components/datetime";
import flopOption from "./components/flop";
import funnelOption from "./components/funnel";
import gaugeOption from "./components/gauge";
import iframeOption from "./components/iframe";
import imgOption from "./components/img";
import imgborderOption from "./components/imgborder";
import lineOption from "./components/line";
import mapOption from "./components/map";
import pictorialbarOption from "./components/pictorialbar";
import pieOption from "./components/pie";
import progressOption from "./components/progress";
import radarOption from "./components/radar";
import scatterOption from "./components/scatter";
import swiperOption from "./components/swiper";
import tableOption from "./components/table";
import tabsOption from "./components/tabs";
import textOption from "./components/text";
import videoOption from "./components/video";
import clapperOption from "./components/clapper";
import clappersOption from "./components/clappers";
import commonOption from "./components/common";
import wordcloudOption from "./components/wordcloud";
import datavOption from "./components/datav";
import timeOption from "./components/time";
import datePickerOption from "./components/datePicker";
import defaultDateOption from "./components/defaultDate";
import yearOption from "./components/year";
import monthOption from "./components/month";
import monthSBOption from "./components/monthSB";
import customPieOption from "./components/customPie";
import customPieTransverseOption from "./components/customPieTransverse";
import detailTableOption from "./components/detailTable";
import threeOption from "./components/three";
import dateDayOption from "./components/day";
import dateDaysOption from "./components/days";
import pie3dOption from "./components/pie3d";
//通用配置
import mainOption from "./components/main";
//自定义组件库
import testOption from "@/components/test/option";
import imgTabsOption from "@/components/imgTabs/option";
import imgListOption from "@/components/imgList/option";
import materialSearchOption from "./components/materialSearch";
import treeOption from "./components/tree";
import inputOption from "./components/input";
import inputSearchOption from "./components/inputSearch";
import popupQuestionOption from "./components/popupQuestion";
import yunClapprsAutoOption from "./components/yunClapprsAuto";
import lawEnforcementRecorderOption from "./components/lawEnforcementRecorder";
export default {
  components: {
    commonOption,
    mainOption,
    barOption,
    datetimeOption,
    flopOption,
    funnelOption,
    gaugeOption,
    iframeOption,
    imgOption,
    imgborderOption,
    lineOption,
    mapOption,
    pictorialbarOption,
    pieOption,
    progressOption,
    radarOption,
    scatterOption,
    swiperOption,
    tableOption,
    tabsOption,
    inputSearchOption,
    textOption,
    videoOption,
    clapperOption,
    clappersOption,
    dateDayOption,
    dateDaysOption,
    wordcloudOption,
    datavOption,
    timeOption,
    testOption,
    imgTabsOption,
    imgListOption,
    datePickerOption,
    defaultDateOption,
    pie3dOption,
    yearOption,
    monthSBOption,
    customPieOption,
    customPieTransverseOption,
    detailTableOption,
    popupQuestionOption,
    materialSearchOption,
    treeOption,
    inputOption,
    threeOption,
    monthOption,
    yunClapprsAutoOption,
    lawEnforcementRecorderOption
  },
};
