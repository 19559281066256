<template>
  <div :class="b()">
    <el-table
      ref="table"
      @cell-click="cellClick"
      :data="dataChart"
      :height="height"
      :border="option.border"
      @mouseenter.native="mouseLeave"
      @mouseleave.native="mouseEnter"
      :cell-class-name="tableCellClassName"
      :cellStyle="cellStyle"
      :row-style="rowStyle"
      @selection-change="selectChange"
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle"
    >
      <el-table-column
        type="index"
        label="序号"
        header-align="center"
        align="center"
        @click.native.stop
        v-if="option.index"
        width="60"
      >
        <span slot-scope="{ $index }">{{ $index + 1 }}</span>
      </el-table-column>
      <template v-for="(item, index) in option.column">
        <el-table-column
          v-if="item.hide !== true && item.edit !== true"
          :show-overflow-tooltip="false"
          :key="index"
          @click.native.stop
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
        </el-table-column>
      </template>
      <el-table-column
        label="操作"
        header-align="center"
        align="center"
        v-if="option.showControl"
        :width="option.controlWidth"
      >
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.fileName"
            class="item"
            effect="dark"
            content="回放"
            placement="top-start"
          >
            <div
              class="margin-left online el-icon-shipin"
              @click="handleBack(row)"
            ></div>
          </el-tooltip>
          <el-tooltip
            v-if="row.deviceSerial"
            class="item"
            effect="dark"
            content="实时播放"
            placement="top-start"
          >
            <div
              class="margin-left el-icon-shipin"
              :class="row.onLine == 1 ? 'online' : 'not-online'"
              @click="handlePlay(row)"
            ></div>
          </el-tooltip>
          <el-tooltip
            v-if="row.deviceSerial"
            class="item"
            effect="dark"
            content="定位"
            placement="top-start"
          >
            <div
              class="margin-left online el-icon-location"
              :class="row.onLine == 1 ? 'online' : 'not-online'"
              @click="handlePosition(row)"
            ></div>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" style="margin-top: 15px" v-show="option.pageShow">
      <el-pagination
        align="center"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="size"
        layout="prev, pager, next "
        :total="total"
      >
      </el-pagination>
    </div>
    <Dialog ref="playDialog" :config="palyDialogConfig">
      <iframe
        style="width: 100%; height: 60vh"
        :src="playurl"
        frameborder="0"
      ></iframe>
    </Dialog>
    <Dialog ref="backDialog" :config="backDialogConfig">
      <iframe
        style="width: 100%; height: 60vh"
        :src="backPlayUrl"
        frameborder="0"
      ></iframe>
    </Dialog>
  </div>
</template>

<script>
import create from "@/echart/create";
import Dialog from "@/page/group/dialog.vue";
import { getPlayUrl } from "@/api/video";
export default create({
  name: "lawEnforcementRecorder",
  components: {
    Dialog,
  },
  data() {
    return {
      currentClickIndex: null,
      headerHeight: 0,
      height: 0,
      scrollCheck: "",
      current: 1, // 当前页码
      total: 0, // 总条数
      size: 10, // 每页的数据条数
      extentParam: {},
      palyDialogConfig: {
        top: "13vh !important",
        title: "视频监控",
        width: "65%",
      },
      backDialogConfig: {
        top: "13vh !important",
        title: "视频回放",
        width: "65%",
      },
      playurl: "",
      backPlayUrl: "",
    };
  },
  watch: {
    scrollSpeed() {
      this.setTime();
    },
    scroll: {
      handler() {
        this.setTime();
      },
    },
    dataChart(val) {
      this.setTime();
      setTimeout(() => {
        if (
          this.dataChart &&
          this.dataChart.length > 0 &&
          this.option.pageShow
        ) {
          this.total = this.dataChart[0]._total;
        }
        this.setTime();
      }, this.scrollTime);
    },
  },
  computed: {
    scrollTime() {
      return this.option.scrollTime;
    },
    scrollSpeed() {
      return this.option.scrollSpeed || 1;
    },
    scroll() {
      return this.option.scroll;
    },
    cellHeight() {
      return parseInt((this.height - this.headerHeight) / this.option.count);
    },
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {},
  methods: {
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex;
      column.index = columnIndex;
    },

    handleSizeChange(val) {
      this.current = 1;
      this.size = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.current = val;
      this.updateData({ ...this.extentParam, current: this.current });
    },
    mouseEnter() {
      this.option.scroll = true;
      this.setTime();
    },
    mouseLeave() {
      this.option.scroll = false;
      clearInterval(this.scrollCheck);
    },
    cellClick(row, column, cell, event) {
      this.currentClickIndex = row.index;
      this.updateClick(row);
      this.clickFormatter &&
        this.clickFormatter(
          {
            type: column,
            item: cell,
            row,
            data: this.dataChart,
          },
          this.getItemRefs()
        );
    },
    setTime() {
      if (this.option.count < this.dataChart.length) {
        clearInterval(this.scrollCheck);
        this.height = parseInt(this.$el.clientHeight);
        this.headerHeight = parseInt(
          this.$refs.table.$refs.headerWrapper.clientHeight
        );
        const table = this.$refs.table;
        const divData = table.bodyWrapper;
        const speed = this.scrollSpeed;
        let top = 0;
        if (this.scroll) {
          this.scrollCheck = setInterval(() => {
            top = top + speed;
            divData.scrollTop += speed;
            if (
              divData.clientHeight + divData.scrollTop ==
              divData.scrollHeight
            ) {
              divData.scrollTop = 0;
            }
            if (top >= this.cellHeight && this.scrollTime) {
              divData.scrollTop = divData.scrollTop - (top - this.cellHeight);
              clearInterval(this.scrollCheck);
              setTimeout(() => {
                this.setTime();
              }, this.scrollTime);
            }
          }, 20);
        } else {
          //divData.scrollTop = 0
        }
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return {
        padding: 0,
        height: this.setPx(this.cellHeight),
        fontSize: this.setPx(this.option.bodyFontSize),
        color: this.option.bodyColor,
        textAlign:
          column.type == "index" ? "center" : this.option.bodyTextAlign,
        backgroundColor:
          rowIndex % 2 == 0 ? this.option.othColor : this.option.nthColor,
      };
    },
    rowStyle({ rowIndex }) {
      return {
        backgroundColor: "transparent",
      };
    },
    headerRowStyle() {
      return {
        backgroundColor: this.option.headerBackground,
      };
    },
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: this.setPx(this.option.headerFontSize),
        backgroundColor: this.option.headerBackground,
        color: this.option.headerColor,
        textAlign:
          column.type == "index" ? "center" : this.option.headerTextAlign,
      };
    },
    handlePlay(row) {
      if (row.onLine === 0 || row.enable === 0) {
        this.$message({
          message: "该设备未启用或已离线 无法播放",
          type: "warning",
        });
        return;
      }
      this.mapPosition(row);
      this.playurl =
        "https://" +
        row.serverIp +
        ":7715/static/player/?devid=" +
        row.deviceSerial;
      this.$refs.playDialog.open();
    },

    handlePosition(row) {
      this.mapPosition(row);
      // 重新触发组件数据请求(回放列表)
      refs["a2ed220a-c37f-44d8-b1ba-18f373bd484f"].extentParam = {
        enforcementRecordeInfoId: row.id,
        devId: row.deviceSerial,
        ip: row.serverIp,
        st: "2023-01-01 00:00:01",
        et: this.getCurrentTime() + " 23:59:59",
        fType: 3,
        lType: -1,
      };
      refs["a2ed220a-c37f-44d8-b1ba-18f373bd484f"].handleCurrentChange(1);
    },
    mapPosition(row) {
      // 飞到地图某坐标
      this.$nextTick(() => {
        const refs = this.getItemRefs();
        const map = refs["4a341907-4b93-4699-8a08-a2a71c546e29"];
        let position = { lng: row.lng, lat: row.lat };
        map.panTo(position);
        localStorage.setItem("cameraMapCenter", JSON.stringify(position));
      });
    },
    getCurrentTime() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      return year + "-" + month + "-" + day;
    },
    handleBack(row) {
      let param = {
        enforcementRecordeInfoId: row.enforcementRecordeInfoId,
        id: row.id,
      };
      getPlayUrl(param).then((response) => {
        if (response.data.code === 200) {
          const protocol = window.location.protocol;
          if (protocol === "http:") {
            this.backPlayUrl = response.data.data.httpPrivateUrl;
          } else {
            this.backPlayUrl = response.data.data.pubUrl;
          }
          this.$refs.backDialog.open();
        }
      });
    },
  },
});
</script>
<style scoped lang="scss">
.el-table {
  background-color: transparent;
}
/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: none;
}
/deep/ .el-table::before {
  height: 0;
}
/deep/ .el-pagination .btn-prev {
  padding-right: 5px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  color: #fff;
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

/deep/ .el-pagination .btn-next {
  padding-left: 5px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  color: #fff;
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

/deep/ .el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 28px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  color: #fff;
  background-image: url(../../../../public/img/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

/deep/ .el-pager li.active {
  color: #409eff;
  cursor: default;
}

.online {
  color: greenyellow;
}
.not-online {
  color: rgb(82, 82, 82);
}
.margin-left {
  margin-left: 10px;
}
/deep/.el-dialog {
  background-image: url(../../../assets/dialog-bg.png);
  background-size: 100% 100%;
}
</style>
