import { url } from '@/config';
import request from '../axios'
// export const getList = (params) => request({
//   url: url + '/map/list',
//   method: 'get',
//   params: params
// });
// export const getObj = (id) => request({
//   url: url + '/map/detail',
//   method: 'get',
//   params: {
//     id
//   }
// });
// export const addObj = (data) => request({
//   url: url + '/map/save',
//   method: 'post',
//   data: data
// });
// export const updateObj = (data) => request({
//   url: url + '/map/update',
//   method: 'post',
//   data: data
// });
// export const delObj = (id) => request({
//   url: url + '/map/remove',
//   method: 'post',
//   params: {
//     ids: id
//   }
// });


export const getvideoPage = (params) => request({
  url: '/api/huizhuyun-support/video/getByProjectName',
  // url: '/api/huizhuyun-biboard/video/getByProjectName',
  method: 'get',
  params: params
});

// 项目列表
export const getProjectName = (params) => request({
  url: '/api/huizhuyun-biboard/projectinfo/getProjectNameById',
  method: 'get',
  params: params
});
export const aiBoxInfoPage = (params) => request({
  url: '/api/huizhuyun-biboard/hardware/ai-box-info/kanban/page',
  method: 'get',
  params: params
});
export const videotoken = (data) => request({
  url: '/proxy/icvs/login',
  method: 'post',
  data: data
});

/**
 * 萤石云获取设备列表
 * @param current 当前页
 * @param size 每页显示数量
 * @param params 查询条件
 */
export const getplayVideoPage = (params) => {
  return request({
    url: `/api/huizhuyun-biboard/bifeign/video-config/disk-recorde-info/list-by-projectId`,
    method: 'get',
    params: {
      ...params,
    }
  })
}

/**
 * 获取萤石云token
 * 
 * @param params 查询条件
 */
export const getVideoConfigTokenInfo = () => {
  return request({
    url: `/api/huizhuyun-biboard/bifeign/video-config/getVideoConfigTokenInfo`,
    method: 'get'
  })
}

/**
 * 视频回放参数获取
 */
export const getPlayUrl = (param) => {
  return request({
    url: '/api/huizhuyun-biboard/enforcement-recorde-info/getPlayUrl',
    method: 'post',
    data: param
  })
}