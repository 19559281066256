// import { Loading } from 'element-ui';
import { validatenull } from '@/echart/util'
import { checkUrl, getFunction, obj2qs } from '@/utils/utils'
import { getToken } from '@/utils/auth'
import { Base64 } from 'js-base64';
import axios from 'axios';


window.$glob = {
  url: '',
  params: {},
  query: {},
  headers: {}
};
function getGlobParams() {
  var query = window.location.search.substring(1);
  query = query.replace(/(^|&)projectId=undefined(&|$)/g, '');
  window.history.replaceState(null, null, '?' + query);
  query = query.split("&");
  query.forEach(ele => {
    var pair = ele.split("=");
    if (pair[0] && pair[0] != 'ignoreRequestData') {
      window.$glob.params[pair[0]] = pair[1]
    }
  })
}

axios.defaults.timeout = 10000;
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
// let loadingInstance = '';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  //debugger;
  // loadingInstance = Loading.service({
  //   text: '拼命加载中',
  //   background: 'rgba(0,0,0,0)',
  //   spinner: 'el-icon-loading'
  // });
  config.headers['Authorization'] = `Basic ${Base64.encode(`${window.$website.clientId}:${window.$website.clientSecret}`)}`;
  config.headers['Tenant-Id'] = `${window.$website.tenantId}`
  //让每个请求携带token
  if (getToken()) {
    config.headers[window.$website.tokenHeader] = 'bearer ' + getToken()
  }
  if(localStorage.getItem('managementToken')){
	config.headers[window.$website.tokenHeader] = 'bearer ' + JSON.parse(localStorage.getItem('managementToken'))
  }
  if(config.hasOwnConfig){
    config.headers["Blade-Auth"]= config.BladeAuth,
    config.headers["Authorization"]= config.Authorization,
    config.headers["SwitchToCompanyId"]= config.SwitchToCompanyId
  }
  if(config.hasOwnConfigs){
    config.headers["Blade-Auth"]= config.data['Blade-Auth'] || decodeURI(window.$glob.params['Blade-Auth']),
    config.headers["Authorization"]=  config.data.Authorization || decodeURI( window.$glob.params.Authorization)
    config.headers["SwitchToCompanyId"]=  config.data.SwitchToCompanyId || window.$glob.params.SwitchToCompanyId
  }

  getGlobParams();
  if(config.companyBiboard){
    config.headers["Authorization"]=  'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
    config.headers["Tenant-Id"] =window.$glob.params.tenantId
  }
  if(config.companyBiboardMain){
    config.headers["Authorization"]=  'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
    config.headers["Tenant-Id"] =JSON.parse(localStorage.getItem("managementTenantId"))
    config.headers["Blade-Auth"]= 'bearer '+JSON.parse(localStorage.getItem("managementToken"))
    config.headers["SwitchToCompanyId"]= JSON.parse(localStorage.getItem("switchToCompanyId"))
    window.$glob.params.projectId = '' // 重置参数
  }
  let to_params = obj2qs(window.$glob.params);
  // let to_params = obj2qs({'projectId': window.$glob.params['projectId']});
  if (!checkUrl(config.url)) {
    if (to_params) {
      if (config.url.indexOf("?") > -1) {
        config.url = config.url + '&' + to_params;
        // if(!Object.keys(config.params).includes('projectId')) config.url = config.url + '&' + to_params;
      } else {
        config.url = config.url + '?' + to_params;

          // if(!Object.keys(config.params).includes('projectId')) config.url = config.url + '?' + to_params;
      }
    }
    if(!config.url.includes('/erpManUrl')) {
      config.url = window.$glob.url + config.url;
    }
    //config.url = window.$glob.url + config.url;
    //console.log('config.url: ', config.url);
  }
  if (!validatenull(window.$glob.header)) {
    let header = getFunction(window.$glob.header)();
    config.headers = Object.assign(config.headers, header);
  }
  //获取全局参数
  if (typeof (config.data) === 'object' && JSON.stringify(config.data)!=="{}" && !validatenull(window.$glob.query)) {
    let query = getFunction(window.$glob.query)();
	let params = window.$glob.params
    let data = Object.assign(window.$glob.params, query)
	if(params.onProjectId && data.hasOwnProperty('projectId')){
		delete data.projectId
	}
    if (config.method == 'get') {
      // config.params = Object.assign(config.params, data)
      config.params = config.data
    } else if (config.method == 'post') {
      config.data = Object.assign(config.data, data)
    }
  }
  return config
}, error => {
  return Promise.reject(error)
});
//HTTPrequest拦截
axios.interceptors.response.use(config => {
  // loadingInstance.close();
  return config;
}, error => {
  // loadingInstance.close();
  return Promise.reject(new Error(error));
})

export default axios;
