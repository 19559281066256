<template>
  <el-drawer :visible.sync="drawer"
             :withHeader="false"
             size="361px"
             suffix-icon="el-icon-search"
             direction="ltr">
    <div style="background-color: transparent;"
         class="content-container">
      <div class="smart-board-select">
        <div class="list">
          <p v-for="item in bordTypeList"
             :key="item.urlId"
             class="cell">
            <img src="../../assets/pj-item-pre.png"
                 alt="">
            <a style="cursor:pointer"
               @click="viewBord(item.urlId)">{{item.name}}</a>
          </p>
        </div>
      </div>
    </div>

  </el-drawer>
</template>

<script>
import { setStore } from '@/utils/store'
export default {
  name: 'projectsList',
  data() {
    return {
      drawer: false,
      bordTypeList: [
        {
          name: '塔吊监测',
          urlId: '1578589263728111618'
        },
        {
          name: '升降机监测',
          urlId: '1578589578678398978'
        },
        {
          name: '卸料平台监测',
          urlId: '1578590142359302146'
        },
        {
          name: '环境监测',
          urlId: '1578588847858675713'
        },
        {
          name: '智能水电',
          urlId: '1578590294281187330'
        },
        {
          name: '视频监控',
          urlId: '1584385704832692225'
        },
        {
          name: 'AI视频监测',
          urlId: '1581909323464994817'
        },
        {
          name: '劳务实名制',
          urlId: '1581844131980931073'
        },
        {
          name: '深基坑监测',
          urlId: '1581916498199474177'
        },
        {
          name: '高支模监测',
          urlId: '1582683759856218113'
        },
        {
          name: '智能地磅',
          urlId: '1583001627248455681'
        },
        {
          name: '云监控',
          urlId: '1654364445083701249'
        },
		{
		  name: '安全帽看板',
		  urlId: '1660821912931577858'
		},
		{
		  name: '综合看板',
		  urlId: '1859052896926609409'
		}
      ],
      bordType: 0,
      createUser: null,
      projectId: ''
    }
  },
  created() {
    this.projectId = this.$route.query.projectId

    /* 排除当前页面的跳转选项 */
    let currentPathId = this.$route.params.id
    this.urlIdList = this.bordTypeList.map((item) => {
      return item.urlId
    })
    let currentPathIndex = this.urlIdList.indexOf(currentPathId)
    if (currentPathIndex != -1) {
      this.bordTypeList.splice(currentPathIndex, 1)
    }

    let query = window.location.search.substring(1)
    let windowParams = {}
    query = query.split('&')
    query.forEach((ele) => {
      let pair = ele.split('=')
      if (pair[0]) {
        windowParams[pair[0]] = pair[1]
      }
    })
    if (!windowParams['createUser']) {
      return
    }
    this.createUser = windowParams['createUser']
  },
  computed: {},
  methods: {
    show() {
      this.drawer = true
    },
    /**
     * @param 项目ID
     * 看板跳转处理
     */
    viewBord(urlId) {
      // 跳转到指定看板
      this.$router.replace({
        path: '/view/' + urlId,
        query: { projectId: this.projectId, createUser: this.createUser }
      })
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.smart-board-select {
  margin-top: 20vh;
  background-image: url('../../assets/pj-select-bg.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
}
/deep/ .el-drawer {
  background-color: transparent;
  width: 230px !important;
  box-shadow: unset !important;
}
.search {
  width: 80%;
  margin: 30px;
  background-image: url('../../assets/pj-search-bg.png');
  background-size: 100% 100%;
  /deep/ input {
    background-color: transparent !important;
  }
}
.list {
  flex: 1;
  padding: 20px 22px;
  padding-top: 30px;
  overflow-y: scroll;
  .cell {
    min-height: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &:hover {
      // background-color: #3cb8ea;
      background-color: rgba(60, 184, 234, 0.4);
    }
    img {
      width: 25px;
      height: 25px;
    }
    a {
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>
