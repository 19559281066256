/**
 * 自定义组件参考文档
 * https://cn.vuejs.org/v2/guide/components-registration.html
 */
import Test from "./test/";
import imgList from "./imgList";
import imgTabs from "./imgTabs";
import Test1 from "./test/index1";
import Test2 from "./test/index2";
import Test3 from "./test/index3";
import Test4 from "./test/index4";
import Test5 from "./test/index5";
import Test6 from "./test/index6";
import Test7 from "./test/index7";
import Test8 from "./test/index8";
import Test9 from "./test/index9";
import Test10 from "./test/index10";
import videoHall from "./test/videoHall";
import videoHallAI from "./test/videoHallAI";
// import detailTable from './test/detailTable';
import customAMap from "./test/customAMap";
import safetyVideo from "./test/safetyVideo";
import dailogVideoTwo from "./test/dailogVideoTwo";
import dailogVideoThree from "./test/dailogVideoThree";
import $Echart from "../echart/common";
import WordCloud from "@/components/wordClouds";
import CompanyDetail from "@/components/companyDetail";
import { KEY_COMPONENT_NAME } from "../echart/variable";

export default (() => {
  const list = [
    WordCloud,
    CompanyDetail,
    Test1,
    Test2,
    Test3,
    Test4,
    Test5,
    Test6,
    Test7,
    Test8,
    Test9,
    Test10,
    videoHall,
    videoHallAI,
    // detailTable,customAMap,safetyVideo,
    dailogVideoTwo,
    dailogVideoThree,
    customAMap,
    safetyVideo,
    Test,
    imgList,
    imgTabs,
  ];
  let result = {};
  //循环注册组件
  list.forEach((ele) => {
    ele.mixins = [$Echart];
    result[`${KEY_COMPONENT_NAME}${ele.name}`] = ele;
  });
  return result;
})();
